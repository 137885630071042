
import { defineComponent, PropType } from 'vue';
import { mapGetters, Store } from 'vuex';
import debounce from 'lodash/debounce';

import { _EDIT, _VIEW } from '@shell/config/query-params';
import { randomStr } from '@shell/utils/string';
import { isEmpty } from '@shell/utils/object';

import LabeledSelect from '@shell/components/form/LabeledSelect.vue';
import LabeledInput from '@components/Form/LabeledInput/LabeledInput.vue';
import Checkbox from '@components/Form/Checkbox/Checkbox.vue';
import KeyValue from '@shell/components/form/KeyValue.vue';
import Banner from '@components/Banner/Banner.vue';
import UnitInput from '@shell/components/form/UnitInput.vue';
import FileSelector from '@shell/components/form/FileSelector.vue';

import { MANAGED_TEMPLATE_PREFIX, parseTags } from '../util/aws';
import { AWS } from '../types';
import { DEFAULT_NODE_GROUP_CONFIG } from './CruEKS.vue';

// map between fields in rancher eksConfig and amazon launch templates
const launchTemplateFieldMapping: {[key: string]: string} = {
  imageId:      'ImageId',
  userData:     'UserData',
  instanceType: 'InstanceType',
  ec2SshKey:    '',
  resourceTags: 'TagSpecifications',
  diskSize:     'BlockDeviceMappings'
};

const DEFAULT_USER_DATA =
`MIME-Version: 1.0
Content-Type: multipart/mixed; boundary="==MYBOUNDARY=="

--==MYBOUNDARY==
Content-Type: text/x-shellscript; charset="us-ascii"

#!/bin/bash
echo "Running custom user data script"

--==MYBOUNDARY==--\\`;

export default defineComponent({
  name: 'EKSNodePool',

  components: {
    LabeledInput,
    LabeledSelect,
    KeyValue,
    Banner,
    Checkbox,
    UnitInput,
    FileSelector
  },

  props: {
    nodeRole: {
      type:    String,
      default: ''
    },
    resourceTags: {
      type:    Object,
      default: () => {
        return {};
      }
    },
    requestSpotInstances: {
      type:    Boolean,
      default: false
    },
    spotInstanceTypes: {
      type:    Array,
      default: () => []
    },
    labels: {
      type:    Object,
      default: () => {
        return {};
      }
    },
    tags: {
      type:    Object,
      default: () => {
        return {};
      }
    },
    gpu: {
      type:    Boolean,
      default: false
    },
    userData: {
      type:    String,
      default: ''
    },
    instanceType: {
      type:    String,
      default: ''
    },
    imageId: {
      type:    String,
      default: ''
    },
    desiredSize: {
      type:    [Number, String],
      default: null
    },
    minSize: {
      type:    [Number, String],
      default: null
    },
    maxSize: {
      type:    [Number, String],
      default: null
    },
    diskSize: {
      type:    Number,
      default: null
    },
    ec2SshKey: {
      type:    String,
      default: ''
    },
    nodegroupName: {
      type:    String,
      default: ''
    },
    region: {
      type:    String,
      default: ''
    },
    amazonCredentialSecret: {
      type:    String,
      default: ''
    },

    launchTemplate: {
      type:    Object,
      default: () => {}
    },

    version: {
      type:    String,
      default: ''
    },

    clusterVersion: {
      type:    String,
      default: ''
    },

    originalClusterVersion: {
      type:    String,
      default: ''
    },

    mode: {
      type:    String,
      default: _EDIT
    },
    ec2Roles: {
      type:    Array as PropType<AWS.IamRole[]>,
      default: () => []
    },
    isNewOrUnprovisioned: {
      type:    Boolean,
      default: true
    },

    poolIsNew: {
      type:    Boolean,
      default: false
    },

    instanceTypeOptions: {
      type:    Array,
      default: () => []
    },

    spotInstanceTypeOptions: {
      type:    Array,
      default: () => []
    },

    launchTemplates: {
      type:    Array as PropType<AWS.LaunchTemplate[]>,
      default: () => []
    },

    normanCluster: {
      type:    Object,
      default: null
    },
    loadingInstanceTypes: {
      type:    Boolean,
      default: false
    },

    loadingRoles: {
      type:    Boolean,
      default: false
    },

    loadingLaunchTemplates: {
      type:    Boolean,
      default: false
    },

    rules: {
      type:    Object,
      default: () => {
        return {};
      }
    }
  },

  created() {
    this.debouncedSetValuesFromTemplate = debounce(this.setValuesFromTemplate, 500);
  },

  data() {
    const store = this.$store as Store<any>;
    const t = store.getters['i18n/t'];

    return {
      originalNodeVersion:   this.version,
      defaultTemplateOption: { LaunchTemplateName: t('eks.defaultCreateOne') } as AWS.LaunchTemplate,

      defaultNodeRoleOption:          { RoleName: t('eks.defaultCreateOne') },
      loadingSelectedVersion:         false,
      // once a specific lt has been selected, an additional query is made to get full information on every version of it
      selectedLaunchTemplateInfo:     {} as AWS.LaunchTemplateDetail,
      debouncedSetValuesFromTemplate: null as Function | null,
      // the keyvalue component needs to be re-rendered if the value prop is updated by parent component when as-map=true
      // TODO nb file an issue
      resourceTagKey:                 randomStr()
    };
  },

  watch: {
    selectedLaunchTemplate: {
      handler(neu) {
        if (neu && neu.LaunchTemplateId && this.amazonCredentialSecret) {
          this.fetchLaunchTemplateVersionInfo(this.selectedLaunchTemplate);
        }
      },
      immediate: true
    },

    amazonCredentialSecret: {
      handler() {
        this.fetchLaunchTemplateVersionInfo(this.selectedLaunchTemplate);
      },
      immediate: true
    },

    'selectedVersionData'(neu = {}, old = {}) {
      this.loadingSelectedVersion = true;
      if (this.debouncedSetValuesFromTemplate) {
        this.debouncedSetValuesFromTemplate(neu, old);
      }
    },

    'requestSpotInstances'(neu) {
      if (neu && !this.templateValue('instanceType')) {
        this.$emit('update:instanceType', null);
      } else {
        this.$emit('update:spotInstanceTypes', null);
      }
    },
  },

  computed: {
    ...mapGetters({ t: 'i18n/t' }),

    rancherTemplate() {
      const eksStatus = this.normanCluster?.eksStatus || {};

      return eksStatus.managedLaunchTemplateID;
    },

    hasRancherLaunchTemplate() {
      const eksStatus = this.normanCluster?.eksStatus || {};
      const nodegroupName = this.nodegroupName;
      const nodeGroupTemplateVersion = (eksStatus?.managedLaunchTemplateVersions || {})[nodegroupName];

      return isEmpty(this.launchTemplate) && !isEmpty(eksStatus.managedLaunchTemplateID) && !isEmpty(nodeGroupTemplateVersion);
    },

    hasUserLaunchTemplate() {
      const { launchTemplate = {} } = this;

      return !!launchTemplate?.id && !!launchTemplate?.version;
    },

    hasNoLaunchTemplate() {
      return !this.hasRancherLaunchTemplate && !this.hasUserLaunchTemplate;
    },

    launchTemplateOptions(): AWS.LaunchTemplate[] {
      return [this.defaultTemplateOption, ...this.launchTemplates.filter((template) => !(template?.LaunchTemplateName || '').startsWith(MANAGED_TEMPLATE_PREFIX))];
    },

    selectedLaunchTemplate: {
      get(): AWS.LaunchTemplate {
        if (this.hasRancherLaunchTemplate) {
          return { LaunchTemplateName: this.t('eks.nodeGroups.launchTemplate.rancherManaged', { name: this.rancherTemplate }) };
        }
        const id = this.launchTemplate?.id;

        return this.launchTemplateOptions.find((lt: AWS.LaunchTemplate) => lt.LaunchTemplateId && lt.LaunchTemplateId === id) || this.defaultTemplateOption;
      },
      set(neu: AWS.LaunchTemplate) {
        if (neu.LaunchTemplateName === this.defaultTemplateOption.LaunchTemplateName) {
          this.$emit('update:launchTemplate', {});

          return;
        }
        const name = neu.LaunchTemplateName;
        const id = neu.LaunchTemplateId;
        const version = neu.DefaultVersionNumber;

        this.$emit('update:launchTemplate', {
          name, id, version
        });
      }
    },

    launchTemplateVersionOptions(): number[] {
      if (this.selectedLaunchTemplate && this.selectedLaunchTemplate.LatestVersionNumber) {
        const { LatestVersionNumber } = this.selectedLaunchTemplate;

        return [...Array(LatestVersionNumber).keys()].map((version) => version + 1);
      }

      return [];
    },

    selectedVersionInfo(): AWS.LaunchTemplateVersion | null {
      return (this.selectedLaunchTemplateInfo?.LaunchTemplateVersions || []).find((v: any) => v.VersionNumber === this.launchTemplate.version) || null;
    },

    selectedVersionData(): AWS.LaunchTemplateVersionData | undefined {
      return this.selectedVersionInfo?.LaunchTemplateData;
    },

    displayNodeRole: {
      get() {
        const arn = this.nodeRole;

        if (!arn) {
          return this.defaultNodeRoleOption;
        }

        return this.ec2Roles.find((role: AWS.IamRole) => role.Arn === arn) ;
      },
      set(neu: AWS.IamRole) {
        if (neu.Arn) {
          this.$emit('update:nodeRole', neu.Arn);
        } else {
          this.$emit('update:nodeRole', '');
        }
      }
    },

    userDataPlaceholder() {
      return DEFAULT_USER_DATA;
    },

    poolIsUnprovisioned() {
      return this.isNewOrUnprovisioned || this.poolIsNew;
    },

    clusterWillUpgrade() {
      return this.clusterVersion !== this.originalClusterVersion;
    },

    nodeCanUpgrade() {
      return !this.clusterWillUpgrade && this.originalNodeVersion !== this.clusterVersion && !this.poolIsNew;
    },

    willUpgrade: {
      get() {
        return this.nodeCanUpgrade && this.version === this.clusterVersion;
      },
      set(neu: boolean) {
        if (neu) {
          this.$emit('update:version', this.clusterVersion);
        } else {
          this.$emit('update:version', this.originalNodeVersion);
        }
      }
    },

    isView() {
      return this.mode === _VIEW;
    }
  },

  methods: {
    async fetchLaunchTemplateVersionInfo(launchTemplate: AWS.LaunchTemplate) {
      const { region, amazonCredentialSecret } = this;

      if (!region || !amazonCredentialSecret || this.isView) {
        return;
      }
      const store = this.$store as Store<any>;
      const ec2Client = await store.dispatch('aws/ec2', { region, cloudCredentialId: amazonCredentialSecret });

      try {
        this.selectedLaunchTemplateInfo = await ec2Client.describeLaunchTemplateVersions({ LaunchTemplateId: launchTemplate.LaunchTemplateId, Versions: [...this.launchTemplateVersionOptions] });
      } catch (err) {
        this.$emit('error', err);
      }
    },

    setValuesFromTemplate(neu = {} as AWS.LaunchTemplateVersionData, old = {} as AWS.LaunchTemplateVersionData) {
      Object.keys(launchTemplateFieldMapping).forEach((rancherKey: string) => {
        const awsKey = launchTemplateFieldMapping[rancherKey];

        if (awsKey === 'TagSpecifications') {
          const { TagSpecifications } = neu;

          if (TagSpecifications) {
            const tags = {} as {[key:string]: string};

            TagSpecifications.forEach((tag: {Tags?: {Key: string, Value: string}[], ResourceType?: string}) => {
              if (tag.ResourceType === 'instance' && tag.Tags && tag.Tags.length) {
                Object.assign(tags, parseTags(tag.Tags));
              }
            });
            this.$emit('update:resourceTags', tags);
          } else {
            this.$emit('update:resourceTags', { ...DEFAULT_NODE_GROUP_CONFIG.resourceTags });
          }
        } else if (awsKey === 'BlockDeviceMappings') {
          const { BlockDeviceMappings } = neu;

          if (BlockDeviceMappings && BlockDeviceMappings.length) {
            const size = BlockDeviceMappings[0]?.Ebs?.VolumeSize;

            this.$emit('update:diskSize', size);
          } else {
            this.$emit('update:diskSize', DEFAULT_NODE_GROUP_CONFIG.diskSize);
          }
        } else if (this.templateValue(rancherKey)) {
          this.$emit(`update:${ rancherKey }`, this.templateValue(rancherKey));
        } else {
          this.$emit(`update:${ rancherKey }`, DEFAULT_NODE_GROUP_CONFIG[rancherKey as keyof typeof DEFAULT_NODE_GROUP_CONFIG]);
        }
      });

      this.$nextTick(() => {
        this.resourceTagKey = randomStr();
        this.loadingSelectedVersion = false;
      });
    },

    templateValue(field: string): string | null | AWS.TagSpecification | AWS.TagSpecification[] | AWS.BlockDeviceMapping[] {
      if (this.hasNoLaunchTemplate) {
        return null;
      }

      const launchTemplateKey = launchTemplateFieldMapping[field] as keyof AWS.LaunchTemplateVersionData;

      if (!launchTemplateKey) {
        return null;
      }
      const launchTemplateVal = this.selectedVersionData?.[launchTemplateKey];

      if (launchTemplateVal !== undefined && (!(typeof launchTemplateVal === 'object') || !isEmpty(launchTemplateVal))) {
        if (field === 'diskSize') {
          const blockMapping = launchTemplateVal[0] as AWS.BlockDeviceMapping;

          return blockMapping?.Ebs?.VolumeSize || null;
        }
        if (field === 'resourceTags') {
          const tags = (launchTemplateVal || []) as AWS.TagSpecification[];

          return tags.filter((tag: AWS.TagSpecification) => tag.ResourceType === 'instance')[0];
        }

        return launchTemplateVal;
      }

      return null;
    },
  },
});
